import React from 'react';
import { ReactComponent as Fallback } from '../img/icons/logo.svg';
import { ReactComponent as Logo } from '../img/icons/logo.svg';
import { ReactComponent as Heart_Empty } from '../img/icons/heart_empty.svg';
import { ReactComponent as Heart_Half } from '../img/icons/heart_half.svg';
import { ReactComponent as Heart_Full } from '../img/icons/heart_full.svg';

import { ReactComponent as Apple } from '../img/icons/apple.svg';
import { ReactComponent as Bike } from '../img/icons/bike.svg';
// import { ReactComponent as Book } from '../img/icons/book.svg';
import { ReactComponent as Car } from '../img/icons/car.svg';
import { ReactComponent as Coffee } from '../img/icons/coffee.svg';
import { ReactComponent as Cow } from '../img/icons/cow.svg';
import { ReactComponent as Cutlery } from '../img/icons/cutlery.svg';
import { ReactComponent as House } from '../img/icons/house.svg';
import { ReactComponent as Leaf } from '../img/icons/leaf.svg';
import { ReactComponent as LightBulb } from '../img/icons/light-bulb.svg';
// import { ReactComponent as Logo } from '../img/icons/logo.svg';
import { ReactComponent as Luggage } from '../img/icons/luggage.svg';
import { ReactComponent as MoneyBag } from '../img/icons/money-bag.svg';
import { ReactComponent as Phone } from '../img/icons/phone.svg';
import { ReactComponent as Plane } from '../img/icons/plane.svg';
import { ReactComponent as Ship } from '../img/icons/ship.svg';
// import { ReactComponent as Shoes } from '../img/icons/shoes.svg';
import { ReactComponent as ShoppingCart } from '../img/icons/shopping-cart.svg';
// import { ReactComponent as SVG20200204T200959Z001 } from '../img/icons/SVG-20200204T     200959Z-001.zip';
import { ReactComponent as TShirt } from '../img/icons/t-shirt.svg';
import { ReactComponent as WaterDrop } from '../img/icons/water-drop.svg';
import { ReactComponent as WineGlass } from '../img/icons/wine-glass.svg';
import { ReactComponent as Search } from '../img/icons/search.svg';
import { ReactComponent as SearchSimple } from '../img/icons/search-simple.svg';



function Icon(props){
    var { fill, name, width, height} = props;

    const SVGComponents = {
        'food': Cutlery,
        'transportation': Car,
        'home': House,
        'leisure': Coffee,
        'holiday': Luggage, 
        'shopping': ShoppingCart, 
        'household': LightBulb, 
        'others': TShirt, 
        'online': Phone, 
        'garden': Leaf, 
        'economy': MoneyBag, 
        'onland': Bike, 
        'onwater': Ship, 
        'kitchenbad': WaterDrop, 
        'air': Plane, 
        'gaming': Plane, 
        'streaming': Plane, 
        'party': Plane, 
        'vegetables': Apple, 
        'animals': Cow, 
        'drinks': WineGlass, 

        'heart1': Heart_Full,
        'heart2': Heart_Half,
        'heart3': Heart_Empty,

        'logo': Logo,
        'search': Search,
        'searchSimple': SearchSimple
    }

    const SvgComponent = SVGComponents[name];
    return SvgComponent? <SvgComponent fill={fill} width={width} height={height}/>:<Fallback fill={fill} />
}

export default Icon