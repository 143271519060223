import React, { useEffect, useState } from 'react';
import CategoryButton from '../CategoryButton';
import TipSection from '../TipSection';
import { Link } from "react-scroll";
import CategoryJumbotron from '../CategoryJumbotron';
import ScrollPage from '../ScrollPage';
import { Row, Col } from 'react-bootstrap';
import cats from '../../data/categories.json';

function TipPage(props){
    const [categoryName, setCategoryName] = useState("");
    const [categoryLabel, setCategoryLabel] = useState("");
    const [subCategories, setSubCategories] = useState([]);
       
    const [categoriesCount, setCategoriesCount] = useState(0);
    const [catButtonClass, setCatButtonClass] = useState("");
    const [scrollOffset, setScrollOffset] = useState(0);

    const [tips, setTips] = useState([]);
    const [co2links, setCo2Links] = useState([]);

    useEffect(() => {
        var catName = props.match.params.cat;
        setCategoryName(catName);
        setCategoryLabel(props.match.params.label);
        
        var subCats = cats.filter(cat => cat.parent === catName);
        setSubCategories(subCats);
        
        var catCount = subCats.length;
        setCategoriesCount(catCount);
        setCatButtonClass(catCount % 2 === 0 ? 'category-buttons-4' : 'category-buttons-3');
        setScrollOffset(() => {
            const header = document.getElementById("klimaHeader");
            let offset;
            if (header) {
                let relativePositioned = window.getComputedStyle(header).position === 'relative';
                if (relativePositioned) {
                    offset = 0;
                }
                else {
                    var headerBounds = header.getBoundingClientRect();
                    offset = -headerBounds.bottom;
                }
            }
            else {

                offset = 0;
            }
            return offset;

        });
      }, []);

      useEffect(()=>(setTips(props.tips)), [props.tips])
      useEffect(()=>(setCo2Links(props.co2links)), [props.co2links])


        const subCategoryButtons = subCategories.map(category =>
            <Col key={category._id} xs={6} lg={12 / categoriesCount} className='single-button-container-cat'>
                <Link
                    to={category.name}
                    spy={true}
                    smooth={true}
                    offset={scrollOffset}
                    duration={500}>

                    <CategoryButton
                        className={catButtonClass + ` sub-category-button light-blue`}
                        iconFill="#96c1ba"
                        categoryData={category}
                    />

                </Link>
            </Col>
        )

        const tipSections = subCategories.map((category, index) => {
            return <TipSection key={category._id}
                routingId={category.name}
                categoryName={category.name}
                categoryLabel={category.label}
                tipsInCategory={tips?.filter(tip => tip.category === category.name ).sort( (tip1, tip2) => tip1.climate_effect - tip2.climate_effect )}
                links={co2links}
                idx={index} 
                anchorId={window.location.href.slice(window.location.href.indexOf("#") + 1)}/>
            })

        const pageContent =
            <div>
                <CategoryJumbotron name={categoryName} label={categoryLabel} />
                <div>
                    <div className="sub-button-container page-margin">
                    {categoryName==='food' &&<p className="dark-purple disclaimer-mobile">Nedenstående tips tager kun højde for klimafordel og tager ikke hensyn til sundhed og næringsværdi</p>}
                        <Row className='top-button-row'>
                            {subCategoryButtons}
                        </Row>
                    </div>
                    <div>{tipSections}</div>
                </div>
            </div>

        return (
            <ScrollPage distanceToShow={500} scrollContent={pageContent}></ScrollPage>
        )
    
}

export default TipPage