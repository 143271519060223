import React, { useEffect, useState } from 'react';
import './css/App.scss';
import './css/media.scss';
import './css/Header.scss';
import './css/Jumbotron.scss';
import './css/CategoryButton.scss';
import './css/SourceTable.scss';
import './css/ScrollPage.scss';
import './css/ContactForm.scss';
import Helmet from 'react-helmet';
import LandingPage from './components/pages/LandingPage';
import TipPage from './components/pages/TipPage';
import Partners from './components/pages/Partners'
import About from './components/pages/About';
import Background from './components/pages/Background';
import Contact from './components/pages/Contact';
import Privacy from './components/Privacy';
import { Switch, Route } from "react-router-dom";
import Footer from './components/Footer';
import cats from './data/categories.json'
import SearchResults from './components/pages/SearchResults';
import apiLibrary from './service/apiLibrary';
import CopyRight from './components/pages/CopyRight';
import SearchField from './components/SearchField';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import logo from './img/logo-1.png';
import { NavDropdown } from 'react-bootstrap';
import Forbrug from './components/pages/Forbrug';



function App() {

  const [topCategories, setTopCategories] = useState([]);
  const [downloadedLinks, setDownloadedLinks] = useState([]);
  const [downloadedTips, setDownloadedTips] = useState([]);
  const [expanded, setExpanded] = useState(false);


  useEffect(() => {
    setTopCategories(cats.filter(cat => cat.parent === ""));

    apiLibrary.getAllCO2()
      .then(data => setDownloadedLinks(data))

    apiLibrary.getAllTips()
      .then(data => setDownloadedTips(data))

  }, []);


  function collapseMenuShowing() {
    document.addEventListener('click', handleDocumentClick, true);
  }

  function toggleMenu(e) {
    e.preventDefault();
    setExpanded(!expanded);
  }

  function handleDocumentClick(e) {
    const container = document.getElementById('menuToggleButton');
    if (e.target !== container && !container.contains(e.target)) {
      setExpanded(false);
      document.removeEventListener('click', handleDocumentClick, true);
    }
  }

  return (
    <>
      <Helmet>
        <title>klimasiden | En grønnere retning</title>
        {/* <meta name="description" content="I am description meta tag" />
        <meta name="keywords" content="js-tutorials,keyword,listing" /> */}
        <script src="//collect.privacystats.com/js/ps.js" type="text/javascript"></script>
        <script src="//collect.privacystats.com/collect/?privacystats_c=e7155f2d-cbbb-4acd-bfaa-5e442f110c9b" type="text/javascript"></script>
      </Helmet>
      <header>
        <div className='navbar-wrapper' id="klimaHeader">
          <Navbar collapseOnSelect expand='xl' className='page-margin klima-navbar'>
            <Navbar.Brand as={Link} to='/' className='klima-brand'>
              <img
                alt='Klimasiden logo'
                src={logo}
                className='d-inline-block align-top'
                height='70'
              />
            </Navbar.Brand>

            <SearchField tips={downloadedTips} links={downloadedLinks} />

            <Navbar.Toggle aria-controls='basic-navbar-nav' id="menuToggleButton" />
            <Navbar.Collapse id='basic-navbar-nav' >
              <Nav className='ml-auto'>
                {/* <a className='nav-item' href="http://www.wastefighter.dk" target="_blank" rel="noreferrer noopener">WasteFighter</a>
                <a className='nav-item' href="https://www.redverden.nu/quiz.php" target="_blank" rel="noreferrer noopener">KlimaQuizzen</a> */}
                <NavDropdown title="Spil">
                  <Nav.Link as="a" href="http://www.wastefighter.dk" target="_blank" rel="noreferrer noopener" className='nav-item'>WasteFighter</Nav.Link>
                  <Nav.Link as="a" href="https://www.redverden.nu/quiz.php" target="_blank" rel="noreferrer noopener" className='nav-item'>KlimaQuizzen</Nav.Link>
                </NavDropdown>
                <Nav.Link eventKey="tips" as={Link} to='/.' className='nav-item'> Tips</Nav.Link>
                <NavDropdown title="Viden om">
                  <Nav.Link eventKey="background" as={Link} to='/background' className='nav-item'> Om CO<sub>2</sub></Nav.Link>
                  <Nav.Link eventKey="forbrug" as={Link} to='/forbrug' className='nav-item'> Om forbrug</Nav.Link>
                </NavDropdown>
                <Nav.Link as="a" href="http://www.klimasiden.dk/kostberegner" className='nav-item'>Kostberegner</Nav.Link>
                <NavDropdown title="Om os">
                  <Nav.Link eventKey="about" as={Link} to='/about' className='nav-item'>Klimasiden team</Nav.Link>
                  <Nav.Link eventKey="partners" as={Link} to='/partners' className='nav-item'> Partnere</Nav.Link>
                </NavDropdown>


                <Nav.Link eventKey="contact" as={Link} to='/contact' className='nav-item'> Kontakt </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
      <div className="app-content">
        <Switch >
          <Route path="/partners" component={Partners} />
          <Route path="/about" component={About} />
          <Route path="/search/query=:chars" component={(props) => <SearchResults {...props} />} />
          <Route path="/search" component={(props) => <SearchResults {...props} />} />
          <Route path="/background" component={Background} />
          <Route path="/forbrug" component={Forbrug} />
          <Route path="/contact/privacy" component={Privacy} />
          <Route path="/contact" component={Contact} />
          <Route path="/tips/:cat/:label" render={(props, tips, co2links) => (<TipPage {...props} tips={downloadedTips} co2links={downloadedLinks} />)} />
          <Route path="/copyright" component={CopyRight} />
          <Route path="/"><LandingPage links={topCategories} /></Route>
        </Switch>
      </div>
      <Footer></Footer>
    </>
  )
}

export default App;