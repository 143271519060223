import React from 'react'
import KlimaJumbotron from './KlimaJumbotron'
import ScrollPage from './ScrollPage'
import bgimage from '../img/privacy-banner.jpg'

function Privacy() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    return (
        <>
        <ScrollPage distanceToShow={400}></ScrollPage>
            <KlimaJumbotron backgroundUrl={bgimage} title={<h1 className="wordWrapHeader">Privatlivspolitik</h1>} />
            <div className="container page-margin">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="dark-purple">Hvem er vi</h2>

                        <p>
                            Vi er et non-profit foretagende, hvis mål er at hjælpe almindelige borgere til at træffe klimavenlige valg i hverdagen. Vores web-adresse er klimasiden.dk hvor du befinder dig lige nu.
                        </p>

                        <h2 className="dark-purple">Hvilke personlige data vi indsamler og hvorfor vi indsamler det</h2>
                        <h2 className="dark-purple">Kommentarer</h2>
                        <p>
                            Når besøgende udfylder kontatkformularen, indsamler vi de data, som vises i kontaktformularen, og videresender dem til Klimasidens interne emailadresse, så vi kan besvare forespørgslen. Du er velkommen til at vælge et alias, hvis du ikke har lyst til at bruge dit rigtige navn.
                        </p>

                        {/* <p>
                            En anonymiseret streng som er oprettet ud fra din e-mailadresse (også kaldet et hash), kan leveres til Gravatar tjenesten for at se om du bruger denne. Gravatar tjenestens privatlivspolitik er tilgængelig her: https://automattic.com/privacy/. Efter godkendelse af din kommentar, vil dit profilbillede være synligt for offentligheden sammen med din kommentar.
                        </p> */}

                        <h2 className="dark-purple">Indlejret indhold fra andre websteder</h2>
                        <p>
                            Klimasiden indeholder hverken indlejret video, billeder eller lyd fra tredjepart (fx Youtube) og er således fri for overvågning via cookies fra sådanne websteder.
                            {/* Hvis du uploader billeder til webstedet, så bør du undlade at uploade billeder med indlejrede lokalitetsdata (EXIF GPS) inkluderet. Besøgende på webstedet kan downloade og udtrække alle lokalitetsdata fra billeder på webstedet. */}
                        </p>

                        {/* <h2 class="dark-purple">Kontaktformularer</h2> */}
                        <h2 className="dark-purple">Cookies</h2>
                        <p>
                            Klimasiden placerer ikke cookies på din enhed.
                            {/* Hvis du skriver en kommentar på vores websted, kan du vælge at gemme dit navn, e-mailadresse og websted i cookies. Disse er til din bekvemmeligehed, så du ikke skal udfylde dine oplysninger igen, når du skriver endnu en kommentar. Disse cookies vil holde i et år. */}
                        </p>

                        {/* <p>
                            Hvis du besøger vores loginside, opretter vi en midlertidig cookie for at afgøre om din browser accepterer cookies. Denne cookie indeholder ingen personlige data og slettes, når du lukker din browser.
                        </p>

                        <p>
                            Når du logger ind, vil vi opsætte en række cookies og gemme din logininformation og dine valg af skærmvisning. Login cookies holder i to dage, og skærmvalg cookies holder i et år. Hvis du vælger “Husk mig”, vil dit login holde i to uger. Hvis du logger ud af din konto, vil login cookierne forsvinde.
                        </p>

                        <p>
                            Hvis du redigerer eller udgiver en artikel, vil en yderligere cookie blive gemt i din browser. Denne cookie indeholder ikke nogle personlige data og opgiver simpelthen indlægsID på den artikel, du lige har redigeret. Den udløber efter 1 dag.
                        </p> */}

                        {/* <h2 className="dark-purple">Indlejret indhold fra andre websteder</h2>
                        <p>
                            Artikler på dette websted kan indeholde indlejret indhold (f.eks. videoer, billeder, artikler osv.). Indlejret indhold fra andre websteder opfører sig på nøjagtig samme måde, som hvis den besøgende har besøgt det andet websted.
                        </p>

                        <p>
                            Disse websteder indsamler måske data om dig, bruger cookies, indlejrer ekstra tredjeparts sporing, og overvåger din interaktion med dette indlejrede indhold, heriblandt at spore din interaktion med indlejret indhold, hvis du har en konto og en logget ind på det websted.
                        </p> */}

                        {/* <h2 class="dark-purple">Analytics</h2> */}
                        {/* <h2 class="dark-purple">Hvem vi deler dine data med</h2> */}
                        <h2 className="dark-purple">Brugerstatistik</h2>
                        <p>
                            Klimasiden anvender cookiefri statistik præsenteret på et aggregeret niveau, der gør det umuligt at spore enkeltpersoner. Data gemmes anonymiseret og krypteret, hvilket fjerner personhenførbarheden og dermed mulighed for personlig sporing.
                        </p>

                       
                        <h2 className="dark-purple">Hvor længe vi gemmer dine data</h2>
                        <p>
                        Hvis du skriver en besked til Klimasiden, så bliver beskeden og dens metadata bevaret på ubestemt tid. Dette gør vi, så vi kan følge op på beskeden på et senere tidspunkt, og sammenfatte indkomne beskeder når vi planlægger opdateringer og forbedringer af siden.
                        </p>

                        {/* <p>
                            For brugere som opretter sig på vores websted (om nogen), gemmer vi også den personlige information de giver til deres brugerprofil. Alle brugere kan se, redigere, eller slette deres personlige information til enhver tid (med den undtagelse at de ikke kan ændre deres brugernavn). Webstedets administratorer kan også se og redigere den information.
                        </p> */}

                        <h2 className="dark-purple">Hvilke rettigheder har du over dine data</h2>
                        <p>
                            Hvis du har skrevet beskeder til os, kan du bede om en eksporteret fil med de personlige data, vi har liggende om dig, heriblandt alt data, du har givet os. Du kan også bede om, at vi sletter alle personlige data, vi har om dig. Dette indbefatter ikke nogen form for data, som vi er forpligtede til at gemme af administrative, lovmæssige eller sikkerhedsmæssige grunde.
                        </p>

                        <h2 className="dark-purple">Hvor vi sender dine data</h2>
                        <p>
                            Klimasiden sender ikke data til tredjepart.
                        </p>

                        {/* <h2 class="dark-purple">Din kontaktinformation</h2>
                        <h2 class="dark-purple">Yderligere information</h2>

                        <h2 class="dark-purple">Hvordan vi beskytter dine data</h2>

                        <h2 class="dark-purple">Hvilke databrudsprocedurer vi har på plads.</h2>

                        <h2 class="dark-purple">Hvilke tredjeparter vi modtager data fra</h2>

                        <h2 class="dark-purple">Hvilke automatiserede beslutninger og/eller profilering vi foretager med brugerdata.</h2>

                        <h2 class="dark-purple">Industriregulative krav om fremlæggelse</h2> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Privacy


