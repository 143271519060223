import React from 'react'
import Icon from './Icon'

function HeartRange(props){
    const { iconName, range } = props
    
    return (
        <>
            <div className="heart heart-range-item">
                <Icon fill="#81c2bb" name={iconName} width='80px'/>
                <div>
                    <p className="range-text">Du sparer </p>
                    <p className="range-text bold-text">{range} kg CO<sub>2</sub>e</p>
                    <p className="range-text"> pr. person pr. år</p>
                </div>
            </div>
           
        </>   
    )
}

export default HeartRange