export default {
    markWords : (words, term) => {
    if(words)
    {
        words.forEach(word => {

            var expression = new RegExp(word, "gi");
            term = term.replace(expression, originalWord => {return '<mark>' + originalWord + '</mark>'});
        });
    }
    return term;
    },

    markWordsCustom : (words, term) => {
        if(words)
        {
            words.forEach(word => {
                var expression = new RegExp(word, "gi");
                term = term.replace(expression, originalWord => { return '<span class="search-term">' + originalWord + '</span>'});
            });
        }
        return term;
    }
}
