import React from 'react';
import { Twitter, Facebook, Instagram } from 'react-feather';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="klima-footer">
            <a href="https://www.facebook.com/klimasiden.dk" rel="noopener noreferrer" target="_blank"><Facebook className="filled-icon social-icon"></Facebook></a>
            <a href="https://twitter.com/klimasiden" rel="noopener noreferrer" target="_blank"><Twitter className="filled-icon social-icon"></Twitter></a>
            <a href="https://www.instagram.com/klimasiden.dk/" rel="noopener noreferrer" target="_blank"><Instagram className="no-fill-icon social-icon"></Instagram></a>
            <div className="copyright">
                <p>Copyright 2023 - Klimasiden.</p>
                <p>All rights Reserved</p>
                <p><Link to='/copyright' className='references'>References</Link></p>
            </div>
        </div>
    )
}

export default Footer