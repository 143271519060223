import React, { useState, useEffect } from 'react';
import ScrollPage from '../ScrollPage';
import TipCard from '../TipCard';
import { Accordion } from 'react-bootstrap';
import CagtegoryJumbotron from '../CategoryJumbotron';

function SearchResults(props){
    const [tips, setTips] = useState([]);
    const [query, setQuery] = useState([]);
    const [co2Links, setCo2Links] = useState([]);

    useEffect(() => {
        if(props.location.state){
            setTips(props.location.state.searchResults);
            setQuery(props.location.state.query);
            setCo2Links(props.location.state.co2Links);
        }
      }, [props]);

    const tipCards = tips.map((tip, index) => {

        return <TipCard
            key={tip._id}
            tipData={tip}
            markedWords={query}
            linkData={co2Links.filter((link) => {
                return tip.links.split(",").indexOf(link.link_id) !== -1
            })
            }
            index={index}
        />
    })

    let searchTitle = 'Søgeresultater for " ' + query  + ' "';
    let searchHeader = 'Vi har fundet  ' + tips.length + ' tips til dig:';

    var pageContent = 
    <div>
        <CagtegoryJumbotron name={'search'} label={searchTitle} />
        <div className='page-margin'>
            <h2 dangerouslySetInnerHTML={{ __html: searchHeader }}></h2>
            <Accordion>{tipCards}</Accordion>
            
        </div>
    </div>
    return(
        <ScrollPage distanceToShow={500} scrollContent={pageContent}></ScrollPage>
    )
}

export default SearchResults;