import React, { useState, useRef, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Icon from './Icon';
import Collapse from 'react-bootstrap/Collapse'
import SourceTable from './SourceTable';
import { PlusCircleFill, DashCircleFill, ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import { animateScroll as scroll } from "react-scroll";
import Utils from "../utils/utils"

function TipCard(props) {

    const card = useRef(null);
    const [sourceOpen, setSourceOpen] = useState(false);
    const [cardOpen, setCardOpen] = useState(false);

    useEffect(() => {
        if(props.anchorId && props.anchorId === props.tipData._id){
            setCardOpen(true);
            var cardPositionTop = card.current.getBoundingClientRect().top;
            scrollToTop(cardPositionTop);
        }
    }, [props])

    //event Listeners
    const cardToggleClicked = () => {
        setCardOpen(!cardOpen)
        if (sourceOpen) {
            setSourceOpen(false);
        }
    }

    const sourceTableToggleClicked = (event) => {
        var opening = !sourceOpen;
        setSourceOpen(!sourceOpen);
        var sourceTableTop = event.target.getBoundingClientRect().top;
        if (opening && isPhone) {
            scrollToTop(sourceTableTop);
        }
    }

    //scroll
    const isPhone = window.innerWidth < 767.98;
    const headerBottom = document.getElementById("klimaHeader").getBoundingClientRect().bottom;
    const headerPositionBottom = headerBottom < 0 ? 0 : headerBottom;
    const scrollOptions = {
        duration: 1000,
        smooth: true
    };
    
    const cardBodyEntered = () => {
        var cardPositionTop = card.current.getBoundingClientRect().top;
        if (isPhone || cardPositionTop < headerPositionBottom) {
            scrollToTop(cardPositionTop);
        }
    }

    const scrollToTop = (elementPosition) => {
        let padding = 20;
        scroll.scrollMore(elementPosition - padding - headerPositionBottom, scrollOptions)
    }

    //data
    

    const iconName = 'heart' + props.tipData.climate_effect;
    var heading = Utils.markWordsCustom(props.markedWords, props.tipData.heading).replace(/CO2/gi, 'CO<sub>2</sub>');
    var text = Utils.markWords(props.markedWords, props.tipData.text).replace(/CO2/gi, 'CO<sub>2</sub>').replace(/m([23])/gi, 'm<sup>$1</sup>');
    return (
        <Card  id={props.tipData._id} className='space-after' ref={card}>
            <Accordion.Toggle as={Card.Header} eventKey={props.index} onClick={cardToggleClicked}>
                <div className='flex-container-tip-card-header'>
                    <div className='tip-card-header-icon'> <Icon fill='#81c2bb' width='100%' height='100%' name={iconName} /></div>
                    <h2 className='tip-card-header-text' dangerouslySetInnerHTML={{ __html: heading }}></h2>
                    {cardOpen
                        ? <DashCircleFill className='tip-card-header-opener' width='100%' height='100%' fill='#fff'></DashCircleFill>
                        : <PlusCircleFill className='tip-card-header-opener' width='100%' height='100%' fill='#fff'></PlusCircleFill>
                    }
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={props.index} in={cardOpen}
                onEntered={cardBodyEntered}>
                <Card.Body className='tip-card-body'>

                    <p dangerouslySetInnerHTML={{ __html: text }}></p>
                    <div onClick={sourceTableToggleClicked} className="learn-more-button">

                    Bliv klogere på tallene
                        <span className="float-right">
                            {sourceOpen
                                ? <ChevronUp fill='#fff'></ChevronUp>
                                : <ChevronDown fill='#fff'></ChevronDown>
                            }
                        </span>
                    </div>


                    <Collapse in={sourceOpen}>
                        <div>
                            <SourceTable links={props.linkData} markedWords={props.markedWords}></SourceTable>
                        </div>
                    </Collapse>

                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default TipCard 