import React from 'react'
import KlimaJumbotron from '../KlimaJumbotron'
import Image from 'react-bootstrap/Image'
import Figure from 'react-bootstrap/Figure'

import banner from '../../img/forbrug-banner.webp'
import fb1 from '../../img/forbrug/forbrug-1.webp';
import fb2 from '../../img/forbrug/forbrug-2.webp';
import fb3 from '../../img/forbrug/forbrug-3.webp';

import ScrollPage from '../ScrollPage';



function Forbrug() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    const pageContent =
        <>
            <KlimaJumbotron backgroundUrl={banner} backgroundPositionY='center' title={<h1>Om forbrug</h1>} />

            {/* <div className='container page-margin'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2 className='dark-purple italic'>For at kunne navigere i Klimasidens information, er der nogle basale grundbegreber om CO<sub>2</sub>, der er gode at kunne. Derfor kan du her læse om CO<sub>2</sub> i tal, CO<sub>2</sub> ækvivalenter, livscyklusanalyser og hvordan CO<sub>2</sub> påvirker Jorden. Kort sagt, CO<sub>2</sub> forklaret til alle os helt almindelige danskere, der ikke er klimaforskere!</h2>
                    </div>
                </div>
            </div> */}
            <section className="background">
                <div className='container page-margin'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            {/* <h1>CO<sub>2</sub> i tal</h1> */}
                            <h2 className='dark-purple italic'>I Danmark praler vi af at være et grønt og bæredygtigt foregangsland, men statistikkerne fortæller en anden historie. Vi cykler en del, men vi kører meget mere bil. Vi har vindmøller, men når det ikke blæser, brænder vi olie og naturgas af. Vores største problem er dog vores forbrug af ting og sager, der er blandt de højeste i verden.</h2>

                            <p>Din CO<sub>2</sub>-udledning som individ kan opdeles i tre typer:</p>
                            <ul>
                                <li>Direkte udledninger (kaldet scope 1), fx fra bilkørsel drevet af fossilt brændstof såsom benzin.</li>
                                <li>Indirekte udledninger fra energi man køber (scope 2), fx fra el og varme. </li>
                                <li>Udledninger forårsaget af forbrug (scope 3), fx fra fremstilling af produkter man køber, eller services man anvender.</li>
                            </ul>
                            <p>Indtil for nylig har medierne fokuseret primært på CO<sub>2</sub>-udledninger fra transport og energi (scope 1 og 2) i form af tips om at skifte til LED-pærer og tage cyklen. Og selvom disse tiltag er vigtige, udgør transport og energi kun 18% af en danskers samlede udledning. Dette betyder, at forbrug (scope 3) udgør hele 82% af vores udledning.</p>
                            <p>Af din udledning som dansker udgør det offentlige forbrug 29%, hvilket dækker over fx udledning fra skoler, hospitaler, veje osv. Denne udledning afhænger af politiske beslutninger og kan primært påvirkes via din stemme, eller hvis du selv stiller op til valg.</p>
                            <p>Dit eget forbrug (53%) kan deles op i CO<sub>2</sub>-udledning fra mad (18%), shopping inklusiv bolig og bil (26%), og rejser, oplevelser og services (12%). Det er i disse kategorier, at der er behov for en gentænkning af vores adfærd, for der er masser af CO<sub>2</sub> at spare.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blue background">
                <div className='container page-margin'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Image src={fb1} width='100%' />
                        </div>
                        <div className='col-lg-6'>
                            <h1>Bæredygtigt forbrug</h1>
                            <p>De typiske råd og tips, man læser i medierne, handler om at reducere sit forbrug og omlægge sin kost. Undgå bras fra Kina, køb ting brugt og sælg ting du ikke længere selv bruger, erstat kød med plantebaseret kost, og tag toget i stedet for flyet. Følger du disse råd sparer du CO<sub>2</sub>. Som bonus sparer du også penge, og så er alt godt, ikke?</p>
                            <p>Det korte svar er: måske. Hvis du bruger dine sparede penge på en tur til Thailand, kan det vise sig, at din totale udledning stiger, selvom du har gjort nogle af dine forbrugsvaner klimavenlige. Det er altså vigtigt, at sparepengene også bruges fornuftigt. Dette er dog nemmere sagt end gjort.</p>
                            <p>Lad os kigge på problemstillingen ud fra et eksempel: du har indført et par kødfri dage i din hverdag. Du har sparet CO<sub>2</sub> og penge. Måske har du sparet 1000 kr om måneden. Du har altså fået råd til at købe den fladskærm, du har drømt om eller måske til at tage på dykkertur til Asien, eller måske til at prøve fire Michelinstjerne-restauranter i Danmark. Før du vælger, hvad de sparede penge skal bruges på, er det vigtigt at holde hovedet koldt og tænke bæredygtige tanker.</p>
                            <p>Som bæredygtig tommelfingerregel skal man sørge for at træffe valg, der har lav CO<sub>2</sub>-udledning pr. krone man bruger. Lad os antage, at fladskærmen koster 10,000 kr og udleder 1 ton CO<sub>2</sub> at producere, dykkerturen til Asien koster også 10,000 kr og udleder 6 ton CO<sub>2</sub>, og turene på Michelin-restauranterne koster også 10,000 kr og udleder 40 kg CO<sub>2</sub>. Disse eksempler kan omregnes til CO<sub>2</sub>-udledninger på hhv. 100 g/kr, 600 g/kr, 4 g/kr for fladskærm, dykkertur og restaurantbesøg.</p>
                            <p>Der er altså stor forskel i klimapåvirkningen pr. krone da fladskærmen er 25 gange mere klimabelastende end restaurantbesøgene og dykkerturen er 150 gange mere klimabelastende end restauranten.</p>
                            <p>Anvender man CO<sub>2</sub>-udledning/kr som bæredygtighedsindikator, kan man altså bruge sine penge klimavenligt på to måder: købe dyre ting og/eller ting med lavt CO<sub>2</sub>-aftryk. Finder man ting, der både er dyre og har lavt CO<sub>2</sub>-aftryk (fx Michelin-restauranten), maksimerer man sin bæredygtighed. Dette gælder, for alle de penge man bruger, også de penge man sparer, hvis man fx gradvist spiser mere plantebaseret kost.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="background">
                <div className='container page-margin'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h1>Forbrug med omtanke</h1>
                            <p>Et andet aspekt af bæredygtighed, hvis du bruger penge på materielle goder, er hvor tit du udskifter noget, du allerede ejer. Fx hvor tit skifter du telefon, fladskærm eller sofa? Køber du ny telefon to gange om året, er dit aftryk væsentligt højere, end hvis du kan leve i tre år med samme telefon.</p>
                            <p>Kvalitetsprodukter nævnes ofte som et bæredygtigt valg og med rette, da de typisk er dyre og holder længe. Dermed bruger du masser af penge på en enkelt ting (lav CO<sub>2</sub>-udledning pr. krone), og tingen udskiftes sjældent, da det er kvalitet.</p>
                            <p>Billigt bras fra Kina nævnes tit som en klimasynder og igen med rette. Tingene koster næsten ingenting og er typisk produceret med begrænset hensyn til klimaet (høj CO<sub>2</sub>-udledning pr. krone). Tingene er typisk også af lav kvalitet og går hurtigt i stykker og skal dermed hurtigt erstattes.</p>
                            <p>Generelt er det mere klimavenligt fortrinsvis at betale for andres arbejdstid og i mindre grad for fysiske produkter. Køber man kvalitetsprodukter, betaler man for designere og håndværkeres arbejdstid, og kun en mindre andel af omkostningen udgøres af materialer. Dette er i modsætning til produkter fra bl.a. Kina, hvor lønudgifter er små ift. materiale- og produktionsomkostningerne, der udgør størstedelen af varens ofte lave pris. Man kan sige, at forbrug af tid og idéer slider mindre på kloden end forbrug af energi og materialer.</p>
                            <p>En afgørende faktor for to sammenlignelige produkters CO<sub>2</sub>-udledning pr. krone er deres pris. Har man et budget på 1000 kr til et bord, vil det være mest klimavenligt at købe et bord til 1000 kr i stedet for et tilsvarende bord i lavere kvalitet til 100 kr. CO<sub>2</sub>-udledningen for bordene kan antages at være ens, da meromkostningen primært skyldes arbejdsløn, men CO<sub>2</sub>-udledningen pr. krone er 10 gange højere for det billige bord.</p>
                        </div>
                        <div className='col-lg-6'>
                            <Image src={fb2} width='100%' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="blue background">
                <div className='container page-margin'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Image src={fb3} width='100%' />
                        </div>
                        <div className='col-lg-6'>
                            <h1>Forbrug mindre og få mere tid</h1>
                            <p>En populær aktivitet i Danmark er at shoppe, dvs. købe ting man ikke nødvendigvis har brug for. Shopping handler ofte mere om et behov for selve handlingen end et behov for de anskaffede fysiske produkter. Dette kan fx udmønte sig i, at helt nyt tøj hænger ubrugt i skabet med prismærker på eller, at man har en fladskærm i alle husets rum. Denne adfærd vidner om, at vi i Danmark har en overflod af rigdom, hvilket synes at være en afgørende faktor for danskernes høje CO2-udledning.</p>
                            <p>Ting og tøj bliver dog slidt og går i stykker, og er reparation ikke muligt, kan et reelt behov for et nyt bord indtræffe. Ved at købe brugt undgår man CO2-udledningen fra produktion af et nyt bord og forlænger samtidig levetiden for et eksisterende fungerende produkt, som måske ellers var blevet smidt ud.</p>
                            <p>Forskning har vist, at en stigning i personlig rigdom medfører en stigning i CO2-udledning fra fysiske produkter. Denne proportionalitet mellem rigdom og CO2-udledning (kaldet elasticitet) er særlig udtalt for shopping af fysiske produkter, men også for køb af mobilitet/transport. Dette genkendes i danskernes rejseaktivitet med fly, der stiger støt i takt med faldende flybilletpriser og/eller øget velstand. Når en flyrejse både er billig og udleder meget CO2, resulterer det i en ekstra høj CO2-udledning pr. krone. Det omvendte er tilfældet for togrejser, der generelt er dyre og har en lav CO2-udledning pr. person kilometer.</p>
                            <p>Har du en fast indkomst og bruger du alle dine penge hver måned, er den bæredygtigste levevis at minimere CO2-udledningen pr. krone. Bliver du gradvist mere bæredygtig fx ved at droppe kød og begrænse shopping, vil du opleve at have flere penge til rådighed. Måske flere penge end du egentlig behøver? En mulighed er at investere i bæredygtige virksomheder. Med Danmarks nuværende klimamål er der gode chancer for, at dette er et klogt valg både for kloden og din fremtidige pengepung. Alternativt kan du betragte det øgede økonomiske råderum som en mulighed for at arbejde mindre og få mere fritid, der kan bruges på din yndlingshobby, en tur i skoven, eller god gammeldags hygge med familien.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="white background">
                <div className='container page-margin'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h1>Kilder</h1>
                            <p>Concito - <a href="https://www.concito.dk/sites/concito.dk/files/dokumenter/artikler/rapport-_forbrugerens_klimapaavirkning_udgivelser_21_3706498019.pdf" rel="noopener noreferrer" target="_blank">Forbrugerens klimapåvirkning (2010)</a></p>
                            <p>Concito - <a href="https://concito.dk/sites/concito.dk/files/media/document/Klimavenlige%20madvaner%202019_rev1.pdf" rel="noopener noreferrer" target="_blank"> Klimavenlige madvaner (2019)</a></p>
                        </div>
                    </div>
                </div>
            </section>




        </>
    return (
        <ScrollPage distanceToShow={500} scrollContent={pageContent}></ScrollPage>
    )
}

export default Forbrug