import React from 'react'
import KlimaJumbotron from '../KlimaJumbotron'
import Image from 'react-bootstrap/Image'
import Figure from 'react-bootstrap/Figure'

import banner from '../../img/co2-banner.webp'
import co21 from '../../img/co2/co2-1.webp';
import co22 from '../../img/co2/Klimasiden_Figur_RGB_2019-12-10-01.webp';
import co23 from '../../img/co2/co2-3.webp';
import co24 from '../../img/co2/co2-4.webp';
import co25 from '../../img/co2/co2-5.webp';
import co26 from '../../img/co2/co2-6.webp';
import co27 from '../../img/co2/co2-7.webp';
import ScrollPage from '../ScrollPage';



function Background() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    const pageContent =
        <>
            <KlimaJumbotron backgroundUrl={banner} backgroundPositionY='center' title={<h1>Om CO<sub>2</sub></h1>} />

            <div className='container page-margin'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2 className='dark-purple italic'>For at kunne navigere i Klimasidens information, er der nogle basale grundbegreber om CO<sub>2</sub>, der er gode at kunne. Derfor kan du her læse om CO<sub>2</sub> i tal, CO<sub>2</sub> ækvivalenter, livscyklusanalyser og hvordan CO<sub>2</sub> påvirker Jorden. Kort sagt, CO<sub>2</sub> forklaret til alle os helt almindelige danskere, der ikke er klimaforskere!</h2>
                    </div>
                </div>
            </div>
            <section className="background">
                <div className='container page-margin'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h1>CO<sub>2</sub> i tal</h1>
                            <p>Hvert år udledes ca 50 gigaton CO<sub>2</sub> på verdensplan, et tal der har været stødt stigende siden 1950. Grunden til at den årlige CO<sub>2</sub>-udledning stiger så markant, er primært fordi vi bliver ﬂere og ﬂere mennesker, der bliver rigere og rigere og dermed forbruger mere og mere.<br />
                        En dansker udleder i gennemsnit ca 17 ton CO<sub>2</sub> om året. For at holde os under en temperaturstigning på 2 grader skal vi ned på en årlig udledning på ca 2,3 ton CO<sub>2</sub> pr. verdensborger. Gennemsnittet er lige nu 7,5 ton CO<sub>2</sub> pr. verdensborger.</p>

                            <p>Ud af de 17 ton CO<sub>2</sub> er hver dansker personlig ansvarlig for udledningen af ca 12 ton CO<sub>2</sub>, og det oﬀentlige er ansvarlig for ca 5 ton CO<sub>2</sub> årligt fra bl.a. institutioner, skoler, infrastruktur mm.</p>
                            <p>Der vil altså altid være en andel af vores udledning, vi som enkeltpersoner ikke kan regulere, men som politikerne må tage ansvaret for at nedbringe. Her har det en betydning, om politikerne overholder de løfter, de afgiver om CO<sub>2</sub>-neutralitet indenfor de kommende år.<br />
                        Som borgere har vi også et ansvar.<br />
                        Gennem vores madvaner, rejsemål og shopping kan vi gøre en stor forskel for vores CO<sub>2</sub>-udledning. For en gennemsnitsdansker udgør kosten ca 3 ton CO<sub>2</sub> årligt, hvor shopping, oplevelser og streaming/telefoni samlet udgør 5,5 ton.</p>
                            <p>Vores ferier med ﬂy udleder i snit 1 ton CO<sub>2</sub> årligt, men som det kan ses under ferietips skal man ikke rejse langt mere end 1 gang årligt, før ens personlige udledning vil være langt højere.</p>
                            <p>Vi har nu en enestående chance for at ﬁnde ud af, hvad der giver livskvalitet, og skære det væk, der ikke øger livsglæden, men sætter dybe aftryk på klimaet. Det er samtidig en enestående chance for at spare nogle penge og få mere tid til hinanden.</p>
                            <a href='https://concito.dk/sites/concito.dk/files/dokumenter/artikler/aco2014.pdf' rel="noopener noreferrer" target="_blank">Concito: Annual climate outlook 2014</a>
                        </div>
                        <div className='col-lg-6'>
                            <Image src={co21} width='100%' className='hidden-xs-md'/>
                            <Figure className='scale-for-ie'>
                                <Figure.Image src={co22}/>
                                <Figure.Caption>
                                    Figur: Fordeling af gennemsnitsdanskerens udledning af CO<sub>2</sub>e i ton. Offentlig forbrug ( veje, hospitaler, mv) er politikernes ansvar, resten er forbrugerens.
                                </Figure.Caption>
                            </Figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blue background">
                <div className='container page-margin'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Image src={co23} width='100%' />
                        </div>
                        <div className='col-lg-6'>
                            <h1>CO<sub>2</sub>-ækvivalenter</h1>
                            <p>CO<sub>2</sub> er en af mange drivhusgasser. Andre eksempler på drivhusgasser er lattergas, metan og industrielle gasser såsom freon. Når man skal beregne en aktivitets eller et givent produkts klimapåvirkning, er det således ikke kun CO<sub>2</sub>-udledningen, der skal medregnes. Dog kan det være forvirrende at skulle forholde sig til alt for mange forskellige bidragsydere til klimapåvirkningen.</p>
                            <p>Derfor har man valgt at omregne andre drivhusgassers bidrag til det tilsvarende bidrag fra CO<sub>2</sub> kaldet CO<sub>2</sub>-ækvivalenter, hvilket forkortes CO<sub>2</sub>e.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="background">
                <div className='container page-margin'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h1>Livscyklusanalyse</h1>
                            <p>Når man skal beregne et produkts eller en aktivitets klimapåvirkning, er det aldrig kun produktionen af selve produktet, man skal tage højde for. Der er langt ﬂere trin i udviklingen af et produkt. For at få det fulde overblik over hvor meget CO<sub>2</sub> et produkt udleder, udføres en livscyklusanalyse (LCA).</p>
                            <p>En LCA forsøger at medregne udledningen fra alle trin i processen. Fx til produktion af en t-shirt er det ikke kun selve stoﬀet til t-shirten, der udleder CO<sub>2</sub>. Til fremstilling af stoﬀet er et stykke jord, som måske har været skov, blevet omlagt til bomuldsmark (en sådan omlægning kaldes arealforskydning eller land use change). Rydning af skoven, der før lagrede CO<sub>2</sub>, vil således resultere i en udledning af CO<sub>2</sub>.</p>
                            <p>Derudover har markejeren maskiner, der skal produceres, vedligeholdes og tilføres brændstof. Bomulden skal høstes og transporteres til en fabrik, hvor den forarbejdes, laves til tråd og væves til stof. Fabrikken skal bruge strøm, maskiner og vedligeholdes. Den færdige t-shirt skal nu pakkes og transporteres til Danmark. Her opbevares t-shirten på et lager eller i en butik. Begge dele kræver bygninger, strøm og vedligehold.</p>
                            <p>Alt i alt vil en enkelt t-shirt have været igennem mange trin, før den havner i vores skab. Denne beskrivelse er forsimplet. I en reel LCA medregnes CO<sub>2</sub>-udledningen fra hundredevis af trin og undertrin for at opnå et så korrekt tal som muligt. Man kan kun sjældent lave en komplet LCA, da alle ting er lavet af maskiner, der er lavet af maskiner, der er lavet af maskiner.</p>
                            <p>Når man gennemgår litteraturen og data på CO<sub>2</sub>-udledning beregnet ved LCA, støder man på varierende tal for den samme aktivitet eller produkt. I sådanne situationer har vi her valgt at medtage de højeste CO<sub>2</sub>-angivelser. Det har vi, da høje CO<sub>2</sub>-angivelser typisk er et tegn på en grundig LCA, der medtager ﬂere af fremstillingsprocessens trin.</p>
                        </div>
                        <div className='col-lg-6'>
                            <Image src={co24} width='100%' className='hidden-xs-md'/>
                            <Image src={co25} width='100%' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="blue background">
                <div className='container page-margin'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Image src={co26} width='100%' className='hidden-xs-md'/>
                            <Image src={co27} width='100%' />
                        </div>
                        <div className='col-lg-6'>
                            <h1>Hvad gør CO<sub>2</sub> ved kloden?</h1>
                            <p>CO<sub>2</sub> er en drivhusgas, der, når den udledes til atmosfæren, virker som et drivhus omkring Jorden. Drivhusgasserne lader solens stråler varme jorden op og absorberer denne varme så den ikke forlader kloden igen. Dette betyder, at der ophobes varme omkring Jorden, især i verdenshavene.</p>
                            <p>Varmen får den is, vi har på jorden til at smelte, hvilket får vandstanden i havene til at stige. I løbet af de kommende årtier vil vandstanden formentlig stige markant. Her er det afgørende hvilken is, der smelter. Is, der allerede beﬁnder sig i havene, vil ikke få vandstanden til at stige markant. Is, der beﬁnder sig på landjorden (indlandsis og gletchere), vil derimod øge vandstanden i havene betydeligt, da det bidrager til “nyt” vand i havene. Stigende vandstand medfører et mindre areal for den stigende verdensbefolkning at bo på, og vil især påvirke byer i kystnære områder.</p>
                            <p>Når indlandsisen, der består af ferskvand, smelter, ændres de omkringliggende haves saltindhold. Udover saltindholdet ændres også surhedsgraden, da en stor del af atmosfærens CO<sub>2</sub> optages i havene. Ydermere vil en temperaturstigning i atmosfæren naturligt medføre en temperaturstigning i havene. Disse ændringer i havmiljøet vil påvirke sårbare økosystemer og forskyde eller helt eliminere truede arters habitat.</p>
                            <p>Udover at opbevare vand spiller isen en anden vigtig rolle for Jordens temperatur. Isens hvide overﬂade reﬂekterer solens stråler og reducerer dermed opvarmningen (albedo-eﬀekten). Smelter isen, accelereres opvarmningen altså, hvilket fører til endnu hurtigere afsmeltning i en positiv feedback-sløjfe. Når atmosfæren en tilpas høj temperatur, rammer vi et såkaldt “tipping point”, hvor afsmeltningen ikke kan stoppes igen.</p>
                            <p>Opvarmningen af atmosfæren ændrer også vejret og resulterer i mere og kraftigere regn fordelt på færre dage. Vi oplever oftere ekstremt vejr såsom orkaner, skybrud og tørke. I hele verden ses såkaldte 100 års-hændelser langt oftere end hver 100. år – et tegn på at Jorden er forandret. Særlig tydeligt blev det i Danmark i 2018 med den varme og ekstremt tørre sommer, som medførte afbrændingsforbud, gule græsplæner, visnede træer og store problemer for landbruget og eksporten.</p>
                            <a href='https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_SPM.pdf' rel="noopener noreferrer" target="_blank">IPCC: AR5 synthesis report: climate change 2021</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    return (
        <ScrollPage distanceToShow={500} scrollContent={pageContent}></ScrollPage>
    )
}

export default Background