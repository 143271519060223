import React, { useEffect } from 'react';
import CategoryButton from '../CategoryButton';
import { Link } from "react-router-dom";
import KlimaJumbotron from '../KlimaJumbotron';
import bgimage from '../../img/landingpage-banner.webp';
import Icon from '../Icon';
import { Col, Row } from 'react-bootstrap';

function LandingPage(props) {

    // <Route exact path="/props-through-render" render={(props) => <PropsPage {...props} title={`Props through render`} />} />

    useEffect(() => {
        window.scrollTo(0, 0); // Do the same as in Partners.js to fix Safari?
      });

    const jumbotronTitle =
        <div className='landingpage-title flex-container-landingpage-title'>
            <div className='landingpage-title-icon'><Icon className='logotest' fill="white" name='logo' /></div>
            <div className='landingpage-title-text'>
                <div>
                    <h2>Velkommen til Klimasiden</h2>

                    <p>
                        Klimasiden indeholder faktabaserede tips til hvordan du kan tage små og store valg, der sender dig i en grønnere retning.
                    </p>
                </div>
            </div>
        </div>
    
    const categoryButtons = props.links.map(category =>
        <Col key={category._id} xs={6} lg={3} className='single-button-container-top'>
            <Link to={`tips/${category.name}/${category.label}`}>
                <CategoryButton
                    iconFill="#2e6a65"
                    className='top-category-button category-buttons-4 turkis'
                    categoryData={category}
                />
            </Link>
        </Col>
    )

    return (
        <>
            <KlimaJumbotron backgroundUrl={bgimage} title={jumbotronTitle} />
            <div className="top-button-container page-margin container">
                <Row className='top-button-row'>
                    {categoryButtons}
                </Row>
            </div>
        </>
    )

}

export default LandingPage
