import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'

function KlimaJumbotron(props){
    return (
        <>
          <Jumbotron className='klima-jumbotron all-jumbotron' style={{ backgroundImage: `url(${props.backgroundUrl})`, backgroundPositionY: `${props.backgroundPositionY}`}}>
                <div className='klima-jumbotron-title  page-margin'>
                    {props.title}
                </div>
            </Jumbotron> 
           
        </>   
    )
}

export default KlimaJumbotron