import React from 'react';
import '.././css/TipPage.scss'
import Icon from './Icon';
import TipCard from './TipCard';
import HeartRange from './HeartRange';
import Accordion from 'react-bootstrap/Accordion';

function TipSection(props) {

    const backgroundColor = ['#dfecea', '#b8e0d9', ' #daefec', '#ecfaf8', '#b8e0d9'];

    const { links, tipsInCategory, categoryLabel, categoryName, routingId, idx } = props;

    const backgroundStyle = {
        backgroundColor: backgroundColor[idx],
        width: "100%"
    };

    const tipCards = tipsInCategory?.map((tip, index) => {
        return <TipCard
            key={tip._id}
            tipData={tip}
            linkData={links.filter((link) => {
                return tip.links.split(",").indexOf(link.link_id) !== -1
            })
            }
            anchorId={props.anchorId}
            index={index}
        />
    })

    return (
        <section style={backgroundStyle} >
            <div id={routingId} className='page-margin'>
                <div className='tip-section tip-section-flex-container'>
                    <div className='tip-section-header-item tip-section-header-flex-container'>
                        <div className='tip-section-title-item'>
                            <div ><Icon fill="#1A0C41" name={categoryName}/></div>
                            <h2 className='dark-purple'>{categoryLabel}</h2>
                        </div>
                        <div className='tip-section-hearts-item flex-container-hearts' >
                            <HeartRange range='0-99' iconName='heart3'></HeartRange>
                            <HeartRange range='100-499' iconName='heart2'></HeartRange>
                            <HeartRange range='Over 500' iconName='heart1'></HeartRange>
                        </div>
                    </div>
                    <Accordion className="tip-section-content-item">
                        {tipCards}
                    </Accordion>
                </div>
            </div>
        </section>
    )
}

export default TipSection