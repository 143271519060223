import React from 'react'
import Image from 'react-bootstrap/Image'

function Partner(props) {

    const { imagePath, partnerText, link, changeOrder } = props;
    console.log(changeOrder)

    return (
        <div className='row'>
            {changeOrder==="true" && 
            <div className='col-lg-8 col-sm-12 my-auto' >
                <p>{partnerText}</p>
            </div> }

            <div className='col-lg-4 col-sm-12  my-auto'>

                <a href={link} rel="noopener noreferrer" target="_blank" >
                    {imagePath ? <Image
                        src={imagePath}
                        name={partnerText}
                        width={'100%'}
                    /> : <h3>Fremtidsværkstedet</h3>}
                </a>
            </div>

            {changeOrder==="false" && 
            <div className='col-lg-8 col-sm-12 my-auto' >
                <p>{partnerText}</p>
            </div>}
        </div>

    )
}

export default Partner