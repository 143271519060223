import React from 'react';
import { Link } from "react-router-dom";
import KlimaJumbotron from '../KlimaJumbotron';
import bgimage from '../../img/contact-banner.webp';
import ContactForm from '../ContactForm'

function Contact (){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    return (
        <>
            <KlimaJumbotron backgroundUrl={bgimage} backgroundPositionY='top' title={<h1>Kontakt</h1>}/>
            <div className="container contact-container page-margin">
                <div className="row">
                    <div className="col-lg-6">
                        <p className='big-space-before'>Hvis du har spørgsmål til en kilde, gode ideer til nye tips eller kommentarer i øvrigt til Klimasiden, er du altid velkommen til at kontakte os. Vi vil besvare din henvendelse så hurtigt vi kan. Skriv til os på <a href = "mailto:kontakt@klimasiden.dk">kontakt@klimasiden.dk</a> eller brug kontaktformularen.</p>
                        <p className='big-space-after'>Klimasiden er drevet af frivillige borgere, der ønsker at gøre det let for alle danskere at starte grønne vaner. Hvis du har lyst til at hjælpe med at gøre Klimasiden endnu bedre, så tøv ikke med at kontakte os, stort eller småt.</p>
                        <Link className='big-space-before' to='/contact/privacy'>Privatlivspolitik</Link>
                    </div>
                    <div className="col-lg-6 big-space-before">
                        <ContactForm></ContactForm>
                    </div>
                </div>
            </div>
        </>   
    )
}

export default Contact