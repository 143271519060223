import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Form, FormControl } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';



function SearchField(props){

    const [tips, setTips] = useState([]);
    const [co2Links, setCo2Links] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermArray, setSearchTermArray] = useState([]);

    let history = useHistory();

    useEffect(()=> setTips(props.tips), [props.tips])
    useEffect(()=> setCo2Links(props.links), [props.links])
    useEffect(() => {
        if(searchTermArray.length !== 0)
        {
            findSearchTerms();
        }
    }, [tips, co2Links, searchTermArray])

    function updateSearchTerm(event){
        setSearchTerm(event.target.value);
    }

    function findTips(event){
        event.preventDefault();
        setSearchTermArray(searchTerm.split(" ").filter(element => element !== ""));
    }

    function findSearchTerms(){
        tips.forEach(rankTip);
        var result = tips.filter(tip => tip.rank > 0);
        result.sort((tip1, tip2) => tip2.rank - tip1.rank );

        history.push({
            pathname: '/search',
            search: '?query=' + searchTerm,
            state: { searchResults : result, query: searchTermArray, co2Links: co2Links }
          })
    }

    function rankTip(tip){
        let rank = 0;
        searchTermArray.forEach( searchTerm => {
            if(includesSearchTerm(tip.heading, searchTerm))
            {
                rank += 4;
            }
            if(includesSearchTerm(tip.text, searchTerm))
            {
                rank += 2;
            }
            if(linksIncludeSearchTerm(tip.links, searchTerm))
            {
                rank ++;
            }
        });
        tip.rank = rank;
    }

    function includesSearchTerm(text, searchTerm){
        return text.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
    }

    function linksIncludeSearchTerm(tipLinkIds, searchTerm){
        var tipLinkIdArray  = tipLinkIds.split(",");
        var co2linksForTip = co2Links.filter(co2link => tipLinkIdArray.some(tipLinkId => tipLinkId === co2link.link_id));
        return co2linksForTip.some( link => 
            link.activity.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 || link.source_title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
        );
    }

    return (
        <Form inline onSubmit={findTips}>
            <div className='search-field-container'>
                <Search className='search-icon'/>
                <FormControl type='text' placeholder='Søg' className='mr-sm-2' value={searchTerm} onChange={updateSearchTerm}></FormControl>
                {/* <Button type='button' variant='outline-success' onClick={findTips}>Search</Button> */}
            </div>
        </Form>
    )

}

export default withRouter(SearchField);