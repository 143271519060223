import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Icon from './Icon'

function CagtegoryJumbotron(props) {
    const { name, label } = props;
    return (
        <Jumbotron className='cat-jumbotron all-jumbotron'>
            <div className="container cat-jumbotron-title">
                <h1 className="dark-purple">{label}</h1>
                <div className='cat-jumbotron-title-icon'><Icon fill="#1A0C41" name={name} /></div>
                {name === 'food' &&<p className="dark-purple disclaimer">Nedenstående tips tager kun højde for klimafordel og tager ikke hensyn til sundhed og næringsværdi</p>}
            </div>
        </Jumbotron>
    )
}

export default CagtegoryJumbotron