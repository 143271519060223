import React from 'react';
import Icon from './Icon';


function CategoryButton(props) {

    const { name, label } = props.categoryData;
    return (
        <div className={props.className + ` category-button`} >
            <div className='category-button-content'>
                <div className='category-icon'>
                    <Icon fill={props.iconFill} name={name} />
                </div>
                <h2>{label}</h2>
            </div>
        </div>
    )
}

export default CategoryButton