import React from  'react';
import { animateScroll as scroll } from "react-scroll";

import Button from "react-bootstrap/Button";
import { ChevronUp } from 'react-bootstrap-icons';

class ScrollPage extends React.Component 
{
    constructor() 
    {
        super()
        this.scrollToTop = this.scrollToTop.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount()
    {
        window.addEventListener('scroll', this.handleScroll);
        window.scrollTo(0, 0);
    }
    
    componentWillUnmount()
    {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) 
    {
        if (document.body.scrollTop > this.props.distanceToShow || document.documentElement.scrollTop > this.props.distanceToShow) 
        {
            document.getElementById('back2Top').style.display = "block";
        } 
        else 
        {
            document.getElementById('back2Top').style.display = "none";
        }
    };
    
    scrollToTop = () => {
        scroll.scrollToTop();
    };

    render(){
        return(
            <div id='scrollPage'> 
                {this.props.scrollContent}
                <Button onClick={this.scrollToTop} id="back2Top" title="Go to top" aria-label="Go to top">
                    <ChevronUp></ChevronUp>
                </Button> 
            </div>
        )
    }
}

export default ScrollPage