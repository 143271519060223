import React from 'react'
import KlimaJumbotron from '../KlimaJumbotron'
import Partner from '../Partner'
import banner from '../../img/lessons-banner.webp'
import ScrollPage from '../ScrollPage';

import furseo from '../../img/partners/furesø_kommune.jpg';
import danmark from '../../img/partners/danmark_for_målene.jpg';
import legMedIt from '../../img/partners/leg_med_it.webp';
import meebook from '../../img/partners/meebook.jpg';
import teksperiment from '../../img/partners/teksperimentet_blå.jpg';

function Partners() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    const pageContent =
        <>
            <KlimaJumbotron backgroundUrl={banner} backgroundPositionY='center' title={<h1>Partnere</h1>} />

            <div className='container page-margin'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2 className='dark-purple italic'>I arbejdet med at forbedre Klimasiden har vi arbejdet sammen med flere forskellige aktører. Nedenfor ser i en kort introduktion til disse med direkte links, såfremt i ønsker at læse mere om deres arbejde. Sidder du med gode ideer til andre samarbejdspartnere, så kontakt os endelig på kontakt@klimasiden.dk.</h2>
                    </div>
                </div>
            </div>

            <section className="background">
                <div className='container page-margin'>
                    <div className='row'>
                        <Partner imagePath={furseo} 
                        partnerText='Klimasiden har siden december 2019 haft et samarbejde med Furesø Kommune. Furesø Kommune har givet midler til hjemmesidedesign og udvikling ved opstart. Dog har samarbejdet udviklet sig til at kommunen nu bruger Klimasiden i deres grønne omstilling. Eksempler på hvordan Furesø Kommune bruger Klimasiden kan ses på de nedenstående samarbejdspartnere. Kommunen er kun bruger af Klimasiden, og har derfor ingen indflydelse på indholdet.'
                        link='https://www.furesoe.dk/borger/' 
                        changeOrder="false"/>
                    </div>
                </div>
            </section>


            <section className="water background">
                <div className='container page-margin'>
                    <div className='row'>
                        <Partner imagePath={danmark} 
                        partnerText='Danmark for Målene handler om at udbrede kendskabet til Verdensmålene i hele Danmark. De 17 verdensmål skal sikre én bæredygtig verden for alle inden 2030. Danmark for målene tager rundt i hele landet med deres Verdensmålsfestival i samarbejde med kommuner, virksomheder og organisationer. Klimasiden deltog i Danmark for målene i Furesø kommune i efteråret 2020.'
                        link='https://www.danmarkformaalene.dk/'
                        changeOrder="true" />
                    </div>
                </div>
            </section>

            <section className="background">
                <div className='container page-margin'>
                    <div className='row'>
                        <Partner imagePath={legMedIt} 
                        partnerText='Leg med IT udvikler en lang række kreative undervisningsmaterialer om FN’s Verdensmål. Bag undervisningsmaterialerne står Rikke Falkenberg Kofoed, som har været sparringspartner for Klimasiden i forbindelse med læringsmaterialer og arbejdet med børn.'
                        link='https://www.legmedit.dk/'
                        changeOrder="false" />
                    </div>
                </div>
            </section>


            <section className="blue background">
                <div className='container page-margin'>
                    <div className='row'>
                        <Partner imagePath={meebook} 
                        partnerText='Meebook er en læringsplatform til planlægning, afvikling og evaluering af undervisning i folkeskolen og på ungdomsuddannelser. Klimasiden bruges gennem denne platform som led i undervisning om klima-problematikker og læring om klimaforandringerne.'
                        link='https://meebook.com/'
                        changeOrder="true" />
                    </div>
                </div>
            </section>

            <section className="background">
                <div className='container page-margin'>
                    <div className='row'>
                        <Partner imagePath={teksperiment} 
                        partnerText='Teksperimentet er et læringsmateriale, som skal skabe begejstring og udvikle digitale kompetencer hos folkeskoleelever i natur- og teknologiundervisningen. Klimasiden har stillet cases til rådighed for Teksperimentet under forløb udført i Furesø Kommune. Her skal børnene blandt andet udvikle en supplerende Klimaside i børnehøjde.'
                        link='https://www.teknologipagten.dk/teknologipagten/nyheder/nyheder-teknologipagten/teknologipagten-lancerer-teksperimentet '
                        changeOrder="false" />
                    </div>
                </div>
            </section>

            <section className="water background">
                <div className='container page-margin'>
                    <div className='row'>
                        <Partner
                            partnerText = 'Fremtidsværkstedet er Furesø kommunes lokale makerspace. Her rustes eleverne til den teknologiske og digitale fremtid. De skal lære at få den gode idé, udvikle på den og til sidst skabe den færdige prototype. Lokale virksomheder byder ind med cases, der træner skoleeleverne i de måske vigtigste kompetencer i det 21. århundrede: Problemløsning, kommunikation, samarbejde og vidensdeling. Teksperimentet er et læringsmateriale brugt her.'
                            link='https://www.furesoe.dk/borger/dagtilbud-skole-og-unge/skoler/fremtidsvaerkstedet/'
                            changeOrder="true"/>
                    </div>
                </div>
            </section>






        </>
    return (
        <ScrollPage distanceToShow={500} scrollContent={pageContent}></ScrollPage>
    )
}

export default Partners