import React from 'react'
import KlimaJumbotron from '../KlimaJumbotron'
import Member from '../Member'
import Image from 'react-bootstrap/Image'
import ScrollPage from '../ScrollPage';

import banner from '../../img/about-banner.webp'
import about1 from '../../img/about/about-1.webp';
import about2 from '../../img/about/about-2.webp';
import signe from '../../img/members/signe.jpg';
import martin from '../../img/members/martin.jpg';
import alex from '../../img/members/alex.jpg';
import tina from '../../img/members/tina.jpg';
import fie from '../../img/members/fie.jpg';
import kristian from '../../img/members/kristian.jpg';

function About() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    const pageContent =
        <>
            <KlimaJumbotron backgroundUrl={banner} backgroundPositionY='center' title={<h1>Om os</h1>} />
            <div className='container page-margin'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2 className='dark-purple italic'>Her på siden ﬁnder du tips til, hvordan du med store og små skridt kan leve mere klimavenligt. Vi er en håndbog til den travle dansker, der vil undgå clickbait og sensationsjournalistik og i stedet interesserer sig for fakta, men ikke har tid til at dykke dybt i lange forskningsrapporter. Vi har læst de tunge tekster og sorteret kildekritisk i artikler, så du kan få essensen af den mest essentielle viden om klimavenlig livsstil præsenteret kort og præcist.</h2>
                    </div>
                </div>
            </div>
            <section className='background'>
                <div className='container page-margin'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <p>I Danmark har vi det godt. Så godt at vi faktisk er et af de lande i verden, der udleder allermest CO<sub>2</sub> pr. indbygger. En gennemsnitsdansker udleder nemlig 17 ton CO<sub>2</sub> om året. For at sætte det i relief, skal vi og resten af verden, for at holde os under 2 graders stigning i den globale opvarmning, helt ned under 2,3 ton CO<sub>2</sub> / person / år. Dette mål er vi desværre stadig langt fra, da gennemsnitsudledningen for en verdensborger i dag er 7,5 ton CO<sub>2</sub> / år.</p>
                            <p>Heldigvis viser ﬂere og ﬂere af os danskere interesse for at leve mere bæredygtigt, og efterspørgslen på gode råd er stor. Desværre synes hele ni ud af ti danskere at det er svært at ﬁnde viden om klimavenlig livsstil, på trods af at internettet bugner af information. For at ﬁnde ud af hvilke handlinger, der virkelig batter, skal man læse mange lange artikler, høre podcasts og se dokumentarer og debatter på tv. Man skal derefter prøve at skille skidt fra kanel, hvilket er svært da klimabudskaberne varierer, afhængig af om de kommer fra ﬂyselskaber eller veganere.</p>
                        </div>
                        <div className='col-lg-6'>
                            <Image src={about1} width='100%' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2 className='dark-purple'>Vi har derfor udviklet en platform, der samler de vigtigste råd om bæredygtig livsstil, på en overskuelig og lettilgængelig måde.</h2>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-6'>
                            <p>Vores data stammer fra solide kilder, som fx EU, Klimarådet, Concito og universiteter, og vi linker altid direkte til det dokument eller den hjemmeside, hvor informationen kommer fra. Når en solid kilde ikke har været til rådighed, har vi tydeligt indikeret, at den anvendte kilde er tvivlsom. Vi lægger stor vægt på, at du altid kan genfinde de rapporter, der ligger til grund for vores tal og tips, således at du let kan få den fulde baggrund for et emne, hvis du vil vide mere.</p>
                            <Image
                                src={about2}
                                width={'100%'}
                            />

                        </div>
                        <div className='col-lg-6'>

                            <p>Vi håber på, gennem korte og præcise faktabaserede tips, at give dig, der gerne vil leve lidt grønnere, et værktøj til at komme i gang. Et værktøj til at kunne prioritere hvilke ændringer i hverdagen der giver mest mening for dig. Vi har rangeret alle tips i tre niveauer efter hvilken effekt handlingen har på klimaet, hvor de tips der batter mest har et helt udfyldt hjerte. Således håber vi at gøre det let og overskueligt at vælge de tiltag som har den største gavnlige effekt for klimaet. Vores mission er nemlig at gøre klimavenlig livsstil til en livsstil for alle</p>
                            <p>Siden er skabt af almindelige borgere som en hjælp til almindelige borgere. Arbejdet med siden udføres af et hold bestående af frivillige, der har fået økonomisk hjælp af Furesø kommune til at dække etableringsomkostningerne.</p>
                        </div>
                    </div>
                </div >
            </section>
            <section className="blue background">
                <div className='container big-space-before page-margin'>
                    <h1 className='dark-purple big-space-after'>Klimasidens team</h1>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6'>
                            <Member imagePath={signe} memberName='Signe Micas Pedersen' />
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <Member imagePath={martin} memberName='Martin Wæver Pedersen' />
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <Member imagePath={alex} memberName='Alexandros Kokkalis' />
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <Member imagePath={tina} memberName='Christina Kokkalis' />
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <Member imagePath={fie} memberName='Fie Hollis' />
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <Member imagePath={kristian} memberName='Kristian Thorsen' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    return (
        <ScrollPage distanceToShow={500} scrollContent={pageContent} />
    )
}

export default About