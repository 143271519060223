import React from 'react'
import { Figure } from 'react-bootstrap';

function Member(props) {

    const { imagePath, memberName } = props;

    return (
        <div>
            <Figure>
                <Figure.Image
                    src={imagePath}
                    alt={memberName}
                    name={memberName}
                    width={'100%'}
                />
                <Figure.Caption className='dark-purple image-caption big-space-after space-before'>{memberName}</Figure.Caption>
            </Figure>
        </div>
    )
}

export default Member