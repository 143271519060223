import React from "react"
import Utils from '../utils/utils';

function SourceTable(props) {

    let activity;
    let sourceTitle;
    let groupClassName;
    let co2e;

    const linkEntries = props.links.map((link, index) => {
        activity = Utils.markWords(props.markedWords, link.activity).replace(/CO2/gi, 'CO<sub>2</sub>');
        sourceTitle = Utils.markWords(props.markedWords, link.source_title).replace(/CO2/gi, 'CO<sub>2</sub>');
        groupClassName = 'list-style flex-table-entry entry-separator';
        index === 0 && (groupClassName += ' first-row');
        co2e = link.co2e  + "&nbsp"+ link.co2e_unit
        return (
            <ul className={groupClassName} role="rowgroup" key={link._id}>
                <li className="flex-table-data flex-item-activity" role="rowgroup">
                    <div className="flex-cell-header source-table-cell" role="columnheader">Aktivitet</div>
                    <div className="flex-cell-data source-table-cell" role="cell" dangerouslySetInnerHTML={{ __html: activity }}></div>
                </li>
                <li className="flex-table-data flex-item-co2" role="rowgroup">
                    <div className="flex-cell-header source-table-cell" role="columnheader">CO<sub>2</sub>e</div>
                    <div className="flex-cell-data source-table-cell" role="cell" dangerouslySetInnerHTML={{ __html: co2e }}></div>
                </li>
                <li className="flex-table-data flex-item-source-link" role="rowgroup">
                    <div className="flex-cell-header source-table-cell" role="columnheader">Kilde</div>
                    <div className="flex-cell-data source-table-cell" role="cell"><a href={link.source_link} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: sourceTitle }}></a> {link.questionable && <i>Kilde: Tvivlsom</i>}</div>
                </li>
            </ul>





            //    <div class="list-style" role="rowgroup" key={link._id}>
            //         <td dangerouslySetInnerHTML={{ __html: activity }}></td>
            //         <td> {link.co2e + link.co2e_unit} </td>
            //         <td> <a href={link.source_link} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: sourceTitle }}></a> {link.questionable && <i>Kilde: Tvivlsom</i>}</td>
            //     </div>
        )
    })

    return (
        <div className='flex-table-container source-table' role='table' aria-label='Kilder'>
            <ul className='list-style flex-table-entry flex-column-header' role="table-header">
                <li className="flex-item-activity source-table-cell" role="rowgroup">
                    Aktivitet
                </li>
                <li className="flex-item-co2 source-table-cell" role="rowgroup">
                    CO<sub>2</sub>e
                </li>
                <li className="flex-item-source-link source-table-cell" role="rowgroup">
                    Kilde
                </li>
            </ul>
            {linkEntries}
        </div >
    )
}

export default SourceTable

    // < div class="table-container" role = "table" aria - label="Destinations" >
    //     <div class="flex-table header" role="rowgroup">
    //         <div class="flex-row first" role="columnheader">Country</div>
    //         <div class="flex-row" role="columnheader">Events</div>
    //         <div class="flex-row" role="columnheader">Time</div>
    //         <div class="flex-row" role="columnheader">Fees</div>
    //     </div>
    //     <div class="flex-table row" role="rowgroup">
    //         <div class="flex-row first" role="cell"><span class="flag-icon flag-icon-gb"></span> United Kingdom</div>
    //         <div class="flex-row" role="cell">Stonehenge, Windsor and Bath with Pub Lunch </div>
    //         <div class="flex-row" role="cell">19 Sep, 1p.m.</div>
    //         <div class="flex-row" role="cell">US$500</div>
    //     </div>