//const baseUrl = 'https://mitklima-apidev.herokuapp.com'
const baseUrl = 'http://tastyhaystack.xyz:3000'


export default {
    getAllCategories()
    {
        var url = baseUrl + '/category'
        return getData (url)
    },

    getTopCategories()
    {
        var url = baseUrl + '/category/top'
        return getData(url);
    },

    getSubCategories(parent)
    {
        var url = baseUrl + '/category/' + parent + '/children'
        return getData(url)
    },

    getAllTips()//tips
    {
        var url = 'https://klimasiden.dk/data/tips.json';
        return getData(url)
    },

    getTipsInCategory(category)
    {
        var url = baseUrl + '/tips/category/' + category
        return getData(url)
    },

    getAllCO2() //links
    {
        var url = 'https://klimasiden.dk/data/co2s.json';
        return getData(url)
    }
  };

function getData(url){
   return fetch(url, {cache: "no-cache"})
   //.then(x => new Promise(resolve => setTimeout(() => resolve(x), 5000))//uncomment to simulate server delay
    .then( response => 
        response.json()
    ).catch(err =>
        console.log(err)
    )
}