import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';

function ContactForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [age, setAge] = useState("");
    const [phone, setPhone] = useState("");
    const [topic, setTopic] = useState("");
    const [message, setMessage] = useState("");

    const [showResponse, setShowResponse] = useState(false);
    const [responseBody, setResponseBody] = useState("");

    const successMessage = <p className="response-text">Tak for din henvendelse - vi vender tilbage snarest.</p>
    const errorMessage = 
        <div>
            <p className="response-text">Noget gik galt. Prøv venligst igen senere.</p>
            <Button className="retry-button" onClick={showForm}><span>Tilbage</span></Button>
        </div>
     const invalidEmail = 
     <div>
         <p className="response-text">Den indtastede e-mail er ikke gyldig. Indtast venligst en gyldig e-mail.</p>
         <Button className="retry-button" onClick={showForm}><span>Tilbage</span></Button>
     </div>
      const noEmail = 
      <div>
          <p className="response-text">Indtast venligst din e-mail.</p>
          <Button className="retry-button" onClick={showForm}><span>Tilbage</span></Button>
      </div>
        const noMessage = 
        <div>
            <p className="response-text">Indtast venligst en besked.</p>
            <Button className="retry-button" onClick={showForm}><span>Tilbage</span></Button>
        </div>

    function handleSubmit(event) {
        event.preventDefault();
        console.log("sending!: " + name + email + topic + message + phone);
        const data = { name: name, email: email, age: age, topic: topic, phone: phone, message: message }
        fetch('https://klimasiden.dk/send.php', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(response => {
            if (response.status === 'success') {
                setResponseBody(successMessage);
                resetForm();
               
            } else if (response.status === 'fail') {
                switch(response.error[0]){
                    case "Empty message":
                        setResponseBody(noMessage);
                        break;
                    case "Empty email":
                        setResponseBody(noEmail);
                        break;
                    case "Invalid email":
                        setResponseBody(invalidEmail);
                        break;
                    default:
                        setResponseBody(errorMessage);
                }
            }
            setShowResponse(true);
        })
    }

    function showForm() {
        setResponseBody("");
        setShowResponse(false);
    }

    function resetForm() {
        setName("");
        setEmail("");
        setTopic("");
        setMessage("");
    }

    return (
        <div className="form-container">
            {
                showResponse 
                ? <div>{responseBody}</div>
                : <Form onSubmit={handleSubmit} method="POST">
                <Form.Group>
                    {/* <Form.Label>Navn og evt. firmanavn</Form.Label> */}
                    <Form.Control size="lg" autoComplete="on" type="text" name="name" value={name} placeholder="Navn og evt. firmanavn" onChange={(e) => setName(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    {/* <Form.Label>E-mailadresse</Form.Label> */}
                    <Form.Control size="lg" type="email" name="email" value={email} placeholder="E-mailadresse" onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    {/* <Form.Label>Alder</Form.Label> */}
                    <Form.Control size="lg" type="text" name="age" value={age} placeholder="Din alder?" onChange={(e) => setAge(e.target.value)} className="age-input"/>
                </Form.Group>
                <Form.Group>
                    {/* <Form.Label htmlFor="telefon">Telefon</Form.Label> */}
                    <Form.Control size="lg" type="text" name="phone" value={phone} placeholder="Telefon" onChange={(e) => setPhone(e.target.value)} className="number-input"/>
                     {/* <Form.Label>Emne</Form.Label> */}
                    <Form.Control size="lg" type="text" name="topic" value={topic} placeholder="Hvad drejer det sig om?" onChange={(e) => setTopic(e.target.value)}/>
                    {/* <Form.Control as="select" size="lg" type="text" name="phone" value={topic} placeholder="Valg et emne" onChange={(e) => setTopic(e.target.value)} }>
                        <option disabled value="0">Valg et emne...</option>
                        <option value="1">Jeg har et forslag til tip eller forbedring siden.</option>
                        <option value="2">Jeg vil gerne give feedback til siden.</option>
                        <option value="3">Jeg vil gerne rapportere en fejl på siden. </option>
                        <option value="3">Andet.</option>
                    </Form.Control> */}
                </Form.Group>
                <Form.Group>
                    {/* <Form.Label htmlFor="message">Hvad drejer din henvendelse sig om?</Form.Label> */}
                    <Form.Control size="lg" as="textarea" value={message} rows="6" name="message" placeholder="Her er der plads til din besked." onChange={(e) => setMessage(e.target.value)} />
                </Form.Group>
                <Button className="form-button" variant="primary" type="submit"><span>Send  </span><ChevronRight className="right-arrow"></ChevronRight></Button>
            </Form>
            }
        </div>
    )
}

export default ContactForm 