import React from 'react'
import KlimaJumbotron from '../KlimaJumbotron'
import ScrollPage from '../ScrollPage'
import bgimage from '../../img/privacy-banner.webp'

function CopyRight() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    return (
        <>
        <ScrollPage distanceToShow={400}></ScrollPage>
            <KlimaJumbotron backgroundUrl={bgimage} title={<h1 className="privacyHeading" style={{'overflow-wrap':'break-word'}}>Acknowledgements</h1>} />
            <div className="container page-margin">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="dark-purple">Graphical work</h2>

                        <p>
                           Our page is designed by Annette Hovgaard and all graphical content is provided by her. Nevertheless as our site develops we might include content from other artists and designers. This page is where we add reference to their work as requested by them to support and acknowledge their work.
                        </p>

                        <h2 className="dark-purple">List of content</h2>
                        <p>Big search icon on the search result page: provided by <a href="https://www.flaticon.com/de/autoren/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/de/" title="Flaticon"> www.flaticon.com</a></p>
                        <p><a href="https://www.freepik.com/free-photo/happy-friendship_5399240.htm#page=5&query=leisure&position=43&from_view=search&track=sph">Image by pressfoto</a> on Freepik</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CopyRight


